<template>
  <van-popup v-model="show" position="bottom" round :close-on-click-overlay="false">
    <div class="head van-hairline--bottom">
      <div>
        学校
      </div>
      <div>
        在售
      </div>
      <div>
        下架
      </div>
      <div>

      </div>
    </div>

    <div class="list">
      <div class="item" v-for="item in schoolList" @click="selectSchool(item.school)">
        <div class="van-ellipsis">
          {{item.school.name}}
        </div>
        <div>
          {{ item.onSaleNum }}
        </div>
        <div>
          {{ item.offSaleNum }}
        </div>
        <div>
          >
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script>
import Vue from "vue";

export default {
  name: "SelectSchool",
  data() {
    return {
      show: false,
      schoolList:[],
    }
  },
  created() {
    this.querySchool();
  },
  methods: {
    showPop() {
      this.show = true
    },
    //查询商户的学校
    querySchool() {
      this.$http.get(`/mall/h5_mall/querySchool/${Vue.prototype.deptId}`).then(res => {
        this.schoolList = res.data;
      });
    },
    selectSchool(item) {
      this.$emit('select', item)
      this.show = false
    }
  }
}

</script>
<style lang="less" scoped>
.head {
  display: flex;
  padding: 17px 28px;

  div {
    width: 20%;
    font-size: 12px;
    color: #666;

    &:first-child {
      width: 50%;
    }

    &:last-child {
      width: 10%;
    }
  }
}

.list {
  height: 60vh;
  overflow-y: auto;

  .item {
    display: flex;
    padding: 10px 28px;

    div {
      width: 20%;
      font-size: 12px;
      color: #666;

      &:first-child {
        width: 50%;
        color: #000;
      }

      &:last-child {
        width: 10%;
      }
    }
  }
}
</style>
