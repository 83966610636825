<template>
  <div class="page-gray" style="padding-bottom: 1px">
    <van-sticky>
      <div class="filter">
        <div class="top">
          <div class="left" @click="$refs.SelectSchool.showPop()">
            <img src="./../../assets/images/SchoolUniforms/schoolFilter.png" alt="">
            <span>{{schoolObj.name}}</span>
          </div>
          <van-popover
              v-model="showPopover"
              trigger="click"
              placement="bottom-end"
              :actions="[{
                            text:'全部',
                            value:''
                        },{
                            text:'限时预定',
                             value:'0'
                        },{
                            text:'随时购买',
                             value:'1'
                        }]"
              @select="onSelect"
          >
            <template #reference>
              <div class="right">

                <span>{{ typeName }}</span>
                <img src="./../../assets/images/SchoolUniforms/arrow.png" alt="">
              </div>
            </template>
          </van-popover>
        </div>

        <div class="search van-hairline--bottom">
          <van-search
              v-model="keyword"
              shape="round"
              @search="onSearch"
              background="#fff"
              placeholder="请输入商品名称"
          />
        </div>

        <van-tabs
            v-model="active"
            @click="onClickTabs"
            sticky
            swipeable
            :ellipsis="false"
            :line-width="15"
            :line-height="4">
          <van-tab name="">
            <template #title>
              <div style="line-height: 1;text-align: center;">全部</div>
              <div style="line-height: 1.3;text-align: center;">{{ schoolProductStatistics.allProductCount }}</div>
            </template>
          </van-tab>
          <van-tab name="1">
            <template #title>
              <div style="line-height: 1;text-align: center;">在售商品</div>
              <div style="line-height: 1.3;text-align: center;">{{ schoolProductStatistics.saleProductCount }}</div>
            </template>
          </van-tab>
          <van-tab name="2">
            <template #title>
              <div style="line-height: 1;text-align: center;">下架商品</div>
              <div style="line-height: 1.3;text-align: center;">{{ schoolProductStatistics.offShelfProductCount }}</div>
            </template>
          </van-tab>
        </van-tabs>
      </div>

    </van-sticky>

    <van-list
        v-model="tableLoading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
    >
      <div class="card"  v-for="(item,index) in dataList" :key="index">
        <div class="top">
          <div class="content">
            <img :src="item.image" alt="">
            <div class="info">
              <div class="name van-multi-ellipsis--l2">
                {{ item.name }}
              </div>
              <div class="time">上架时间： {{ item.starttime }}</div>
              <div class="time">截止时间： {{ item.lmtime }}</div>
            </div>
          </div>

          <div class="right">
            <div class="price">
              <span>¥</span>
              <span>{{ item.price }}</span>
            </div>
            <div class="tag" v-if="item.deliveryMode ==1">快递到家</div>
            <div class="tag"  v-if="item.deliveryMode ==2">配送到校</div>
          </div>
        </div>
        <div class="params">
          <div class="title">商品参数</div>
          <van-row :gutter="20">
            <van-col :span="12" v-for="(pack,temp) in item.productPackList" :key="temp">
              <p>品名：{{pack.name}} x {{pack.mxnum}}</p>
              <p>成分：{{pack.productIngredient}}</p>
            </van-col>
          </van-row>
        </div>

        <div class="class">
          <span>上架年级:</span>
          <span v-for="(grade,temp) in item.gradeNameList" :key="temp">
            {{ grade }}
          </span>
        </div>

        <div class="handle van-hairline--top">
          <div class="left" v-if="item.checkflag != 'CK_OK'">
            <div class="state dis">已下架</div>
          </div>

          <div class="left" v-else>
            <div class="state">订购中</div>
            <div class="countdown">
              还剩
              <van-count-down :time="getTime(item.lmtime)" format="DD天HH:mm:ss"/>
            </div>
          </div>
          <div class="btn" @click="$refs.inventory.showPop(item)">查看库存</div>
        </div>

      </div>
    </van-list>


    <select-school ref="SelectSchool" @select="onSchoolChange"/>
    <inventory ref="inventory"/>
  </div>
</template>

<script>
import Inventory from './inventory.vue'
import SelectSchool from './selectSchool.vue'
import Vue from "vue";

export default {
  name: "SchoolUniforms",
  components: {SelectSchool, Inventory},
  data() {
    return {
      showPopover: false,
      active: '0',
      keyword: '',
      type: '',
      typeName: '',
      time: 30 * 60 * 60 * 1000,
      schoolObj:{},
      schoolProductStatistics:{},
      tableLoading:false,
      finished:true,
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 20 // 每页显示多少条
      },
      dataList:[],
    }
  },
  created() {
    Object.assign(this.$data, this.$options.data());
    this.$nextTick(() => {
      this.$refs.SelectSchool.showPop()
    })
  },
  methods: {
    onClickTabs(){
      this.page.currentPage = 1;
      this.dataList = [];
      this.finished = false;
    },
    onSelect(action) {
      this.type = action.value
      this.typeName = action.text
    },
    onSearch() {
      this.page.currentPage = 1;
      this.dataList = [];
      this.finished = false;
    },
    onSchoolChange(school) {
      // TODO
      this.schoolObj = school;
      this.getDataList();
    },
    //查询学校下的数据
    getDataList() {
      //查询学校下商品统计
      this.$http.get(`/product/h5_product/schoolProductStatistics`,{params:{type:this.type,schoolId:this.schoolObj.id}},{
        emulateJSON:true
      }).then(res => {
        this.schoolProductStatistics = res.data;
      });
      this.finished = false;
    },
    onLoad() {
      this.getSaleProducts(this.page,{type:this.type,schoolId:this.schoolObj.id,saleType:this.active,productName:this.keyword})
    },
    //销售商品分页数据
    getSaleProducts(page,params) {
      this.$http.get(`/product/h5_product/schoolSaleProducts`,{params:Object.assign({
          current: page.currentPage,
          size: page.pageSize,
          deptId:Vue.prototype.deptId,
        }, params)},{
        emulateJSON:true
      }).then(response => {
        if(page.currentPage == 1){
          this.dataList = response.data.records
        }else{
          this.dataList = this.dataList.concat(response.data.records)
        }
        this.page.total = response.data.total
        console.log(this.dataList.length,this.page.total)
        if(this.dataList.length >= this.page.total){
          this.finished = true;
        }else{
          this.page.currentPage++
        }
        this.tableLoading = false
      }).catch(() => {
        this.tableLoading = false
      })
    },
    getTime(date){
      return new Date(date.replace(/-/g, "/")).getTime() - Date.now();
    }
  }
}

</script>
<style lang="less" scoped>
::v-deep .van-search__content {
  background-color: #f4f4f4;
}

::v-deep .van-tabs--line .van-tabs__wrap {
  height: 58px;
}


.filter {
  padding-bottom: 5px;
  background-color: #fff;

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 11px 3px;

    .left, .right {
      display: flex;
      align-items: center;
    }

    .left {
      img {
        height: 7px;
        width: 8px;
        margin-right: 7px;
      }

      span {
        font-size: 10px;
        font-weight: 400;
        color: #212121;
        line-height: 1;
      }
    }


    .right {
      img {
        height: 5px;
        width: 9px;
        margin-left: 7px;
      }

      span {
        font-size: 10px;
        font-weight: 400;
        color: #999;
        line-height: 1;
      }
    }
  }
}


.card {
  background: #FEFEFE;
  border-radius: 8px;
  margin: 10px;
  padding: 0 10px 1px;

  .top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .content {
      display: flex;
      align-items: center;
      padding: 10px 0;

      img {
        height: 75px;
        width: 89px;
        min-width: 89px;
        margin-right: 15px;
      }

      .info {
        width: 155px;

        .name {
          font-size: 12px;
          font-weight: 400;
          color: #333333;
          line-height: 15px;
        }

        .time {
          display: block;
          line-height: 1;
          font-size: 10px;
          font-weight: 400;
          color: #999999;
          margin-top: 9px;
        }
      }
    }

    .right {
      text-align: right;
      padding-top: 5px;

      .price {

        span {
          font-size: 12px;
          font-weight: 500;
          color: #D53A26;

          &:last-child {
            font-size: 18px;
            margin-left: 3px;
          }
        }
      }

      .tag {
        border: 1px solid #D53A26;
        border-radius: 2px;
        padding: 2px 5px;

        line-height: 10px;
        font-size: 10px;
        font-weight: 400;
        color: #D53A26;

        margin-top: 5px;

      }
    }
  }
}

.params {
  .title {
    font-size: 10px;
    color: #999;
  }

  p {
    max-width: 135px;
    font-size: 10px;
    font-weight: 400;
    color: #666666;
    line-height: 18px;
  }
}

.class {
  background: #F4F4F4;
  border-radius: 5px;
  padding: 9px 8px;
  margin: 10px 0;
  font-size: 10px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666;

  span {
    color: #999;
  }
}

.handle {
  padding: 10px 0 10px 0;
  display: flex;
  align-items: center;

  justify-content: space-between;

  .left {
    display: flex;
    align-items: center;

    .state {
      background: #4270ED;
      border-radius: 2px;
      line-height: 10px;
      font-size: 10px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #FFFFFF;

      padding: 3px 7px;
      margin-right: 10px;

      &.dis {
        background-color: #999999;
      }
    }

    .countdown {
      font-size: 10px;
      color: #212121;

      .van-count-down {
        display: inline-block;
        font-size: 10px;
        color: #FF0000;
        margin-left: 5px;
      }
    }
  }

  .btn {
    width: 73px;
    height: 25px;
    background: #FDEFF0;
    border-radius: 200px;
    text-align: center;
    line-height: 25px;

    font-size: 12px;
    font-weight: 500;
    color: #D53A26;
  }
}
</style>
