<template>
  <div class="skuBody">

    <div class="skuList">
      <div class="title">规格库存</div>
      <div class="head">
        <div class="item">规格值</div>
        <div class="item">库存总量</div>
        <div class="item">库存类型</div>
      </div>
      <div class="skuItem" v-for="(item, index) in skuList" :style="{
                marginTop: index === 0 ? 0 : '',
                borderRadius: index === 0 ? `0 0 ${px2rem(4)} ${px2rem(4)}` : `${px2rem(4)}`
            }">
        <div class="item">{{ item.skuName }}</div>
        <div class="item">{{ item.stock }}</div>
        <div class="item">
         {{item.libckflag==0?'预售':'现货'}}
        </div>
      </div>
    </div>


    <!--          <van-row class="buttonGroup" :gutter="14">-->
    <!--            <van-col :span="24">-->
    <!--                <van-button block round color="#D53A26" type="primary" size="small" @click="onConfirm">-->
    <!--                    保存-->
    <!--                </van-button>-->
    <!--            </van-col>-->
    <!--        </van-row>-->

  </div>
</template>

<script>
export default {
  name: "SkuUniForm",
  data() {
    return {
      number: 1,
      skuId: '', //当前选中的SKUID
      saleProductId:"",
      productId:"",
      libckflag:0,
      skuName: '',
      showSpecial: false, //是否展示特码输入框
      currentSku: {},
      skuList: []
    }
  },

  computed: {},

  methods: {
    selectSku(item) {
      if (item.isOutOfStock) {
        // 如果是缺货 不可选
        return;
      }

      this.skuId = item.skuId;
      this.skuName = item.skuName
      this.showSpecial = item.isSpecial
      this.currentSku = item
    },

    vaild() {

      if (this.skuList.every(e => e.stepper === 0)) {
        this.$toast('请至少设置一个规格的库存');
        return;
      }


      return true
    },

    onConfirm() {
      if (!this.vaild()) {
        return;
      }
      this.$emit('onSubmit', {type: 'CONFIRM', data: {}}); // 在sku 中发起请求， 这里只是对数据封装
    },
    //传输数据
    transferringData(saleProductId,productId,libckflag){
      Object.assign(this.$data, this.$options.data());
      this.saleProductId=saleProductId;
      this.productId=productId;
      this.libckflag=libckflag;
      this.getMxSkuStock();
    },
    // 获取数据
    getMxSkuStock(){
      //查询总库商品下进销存商品明细
      this.$http.get(`/product/h5_product/queryMxProductStock`,{params:{productId:this.productId,saleProductId:this.saleProductId,libckflag:this.libckflag}},{
        emulateJSON:true
      }).then(res => {
        this.skuList = res.data;
      });
    }
  }
}

</script>
<style lang="less" scoped>
.skuBody {
  padding-bottom: 70px;
}

.skuList {
  background: #EEEEEE;
  border-radius: 13px 13px 0px 0px;
  padding: 0 11px 17px;
  max-height: 320px;
  overflow-y: auto;

  .title {
    line-height: 15px;
    font-size: 15px;
    font-weight: 500;
    color: #000000;
    padding: 14px 0;
  }

  .head, .skuItem {
    display: flex;
    align-items: center;
    height: 30px;
    border-bottom: 1px solid #EEEEEE;
    background-color: #fff;
    border-radius: 4px 4px 0 0;

    .item {
      font-size: 12px;
      padding-left: 18px;
      color: #999999;
      flex: 1;

      &:nth-child(3) {
        min-width: 35%;
      }
    }

    &.skuItem {
      margin: 5px 0;

      .item:nth-child(1) {
        color: #333;
      }

      .item:nth-child(2) {
        color: #FF0000;
      }

    }
  }

  .skuItem:nth-child(1) {
    margin-top: 0;
    border-radius: 0 0 4px 4px;
  }


}

.buttonGroup {
  position: fixed;
  background-color: #fff;
  bottom: 0;
  left: 18px;
  right: 18px;
  padding-top: 14px;
  padding-bottom: 14px;
}

::v-deep .van-stepper__input {
  background-color: #fff;
  margin: 0;

  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
}

</style>
