<template>
  <div>
    <van-action-sheet v-model="show" @closed="showComponent = false">
      <img class="skuClose" @click="show = false" src="@/assets/images/goods/goodsDetail/skuClose.png" alt="">
      <div class="content">
        <div class="goodsInfo">
          <img :src="saleProduct.image" alt="">
          <div class="info">
            <div class="price">
              <span>¥</span>
              <span>{{saleProduct.price}}</span>
            </div>
            <div class="name">
              {{saleProduct.name}}
            </div>
          </div>
        </div>

        <!-- 选择规格方式 -->


      </div>
      <van-tabs
          v-model="productId"
          @click="switchProduct"
          sticky
          swipeable
          :ellipsis="false"
          :line-width="15"
          :line-height="4">
        <van-tab  v-for="(pack,temp) in saleProduct.productPackList" :name="pack.id" :key="temp">
          <template #title>
            <div style="line-height: 1.3;text-align: center;">{{ pack.name }}</div>
          </template>
        </van-tab>
      </van-tabs>
      <div class="skuBody">
        <div style="height: 1px; width: 100%; background-color: #f5f5f5;"></div>
        <sku-uniform
            ref="skuUniform"
            v-if="showComponent"
            @onSubmit="onSubmit"/>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import SkuUniform from './skuUniform.vue'

export default {
  components: {SkuUniform},
  name: "Sku",
  data() {
    return {
      showComponent: false,
      show: false,
      callback: null,
      saleProduct: {},
      productId: '',
    }
  },

  methods: {
    showPop(saleProduct,callback) {
      Object.assign(this.$data, this.$options.data());
      this.showComponent = true
      this.saleProduct = saleProduct
      this.show = true
      if (callback) {
        this.callback = callback
      } else {
        this.callback = null
      }
      this.$nextTick(() => {
        this.$refs.skuUniform.transferringData(this.saleProduct.id,this.productId,this.saleProduct.libckflag)
      })
    },
    // 切换商品
    switchProduct(){
      this.$nextTick(() => {
        this.$refs.skuUniform.transferringData(this.saleProduct.id,this.productId,this.saleProduct.libckflag)
      })
    },
    onSubmit(data) {
      console.log(data.type) // JOINCART / BUY / CONFIRM
      // 所有的数据判断 处理都在组件内完成了， 这里不做任何处理， 直接上发
      // 如果有传callback进来 ， 就会把确认事件提交给外部页面，
      /** 比如这种就是打开的时候传入了回调
       this.$refs.sku.showPop((data) => {
       // TODO
       })
       */

      if (this.callback) {
        this.callback(data)
        this.show = false
        return;
      }

      if (data.type === 'BUY') {
        this.$router.push('/order/submit')
      } else if (data.type === 'JOINCART') {
        // TODO
      } else if (data.type === 'CONFIRM') {
        // TODO
      }

      this.show = false
      // TODO SERVER
      // 否则就发起请求
    }
  }
}
</script>
<style lang="less" scoped>
.skuClose {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 26px;
  right: 19px;
  z-index: 11;
}

.content {
  padding: 0 18px;
  transition: all .2s;
  position: relative;

  .goodsInfo {

    display: flex;
    align-items: flex-end;
    position: sticky;
    top: 0;
    background-color: #fff;
    padding: 24px 0 10px 0;
    z-index: 10;

    img {
      width: 100px;
      height: 84px;
      border-radius: 5px;
      margin-right: 15px;
    }

    .price {
      height: 15px;

      span {
        font-weight: bold;
        color: #D53A26;

        &:first-child {
          font-size: 12px;
          margin-right: 3px;
        }

        &:last-child {
          font-size: 18px;
        }
      }
    }

    .name {
      margin-top: 12px;
      font-size: 10px;
      font-weight: 400;
      color: #333333;
      line-height: 15px;
    }
  }


}

.start {
  height: 25px;
  background: #F3E9FE;
  border-radius: 4px;
  line-height: 25px;
  padding: 0 10px;

  font-size: 10px;
  font-weight: 400;
  color: #C226D5;
  line-height: 25px;

}


.rule {
  width: 100%;
  margin-bottom: 10px;
}
</style>
